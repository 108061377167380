export default {
  title: {
    id: 'forgot_password_page_title',
    defaultMessage: 'Reset the password',
  },
  text: {
    id: 'forgot_password_page_text',
    defaultMessage:
      'Enter the email address associated with your account, and we’ll email you a link to reset your password.',
  },
  metaTitle: {
    id: 'forgot_password_page_meta_title',
    defaultMessage: 'Reset your password',
  },
  metaDescription: {
    id: 'forgot_password_page_meta_description',
    defaultMessage: 'Two clicks to change your password on Back Market.',
  },
}
