export default {
  title: {
    id: 'resetpassword_explanationmodal_title',
    defaultMessage: 'Request password reset',
  },
  paragraphDefault: {
    id: 'resetpassword_explanationmodal_paragraph_default',
    defaultMessage: 'Please reset your password.',
  },
  paragraphPetanqueJpImport1: {
    id: 'resetpassword_explanationmodal_paragraph_petanquejpimport_1',
    defaultMessage:
      'Back Market has renewed its website on April 4, 2023, aiming for a website that customers can use comfortably and with peace of mind.',
  },
  paragraphPetanqueJpImport2: {
    id: 'resetpassword_explanationmodal_paragraph_petanquejpimport_2',
    defaultMessage:
      'Due to the renewal, we are asking customers who already have accounts to reset their passwords in order to safely transfer personal information.',
  },
  paragraphPetanqueJpImport3: {
    id: 'resetpassword_explanationmodal_paragraph_petanquejpimport_3',
    defaultMessage:
      'After clicking "Reset Password", please reset your password from the email sent to you.',
  },
  paragraphPetanqueJpImport4: {
    id: 'resetpassword_explanationmodal_paragraph_petanquejpimport_4',
    defaultMessage:
      'After resetting, you can log in with your registered email and new password. As a thank you for your cooperation, we will send you a coupon code that you can use for future purchases.',
  },
  paragraphPetanqueJpImport5: {
    id: 'resetpassword_explanationmodal_paragraph_petanquejpimport_5',
    defaultMessage: 'Thank you for shopping at Back Market.',
  },
  buttonReset: {
    id: 'resetpassword_explanationmodal_button',
    defaultMessage: 'Reset password',
  },
}
