<template>
  <RevDialog
    :name="MODAL_NAMES.RESET_PASSWORD_EXPLANATION"
    :title="i18n(translations.title)"
  >
    <template #trigger="{ open }">
      <slot name="trigger" :open />
    </template>

    <template #body>
      <div>
        <div
          class="text-static-default-hi body-1 my-32"
          data-test="reset-password-explanation-modal-paragraph"
        >
          <p
            v-for="(paragraph, index) in paragraphTranslated"
            :key="index"
            class="mb-16"
          >
            {{ paragraph }}
          </p>
        </div>
        <div class="mb-24 flex justify-center">
          <div class="w-1/2">
            <RevButton
              data-test="reset-password-explanation-modal-button"
              full-width="always"
              variant="primary"
              @click="handleClickResetPassword"
            >
              {{ i18n(translations.buttonReset) }}
            </RevButton>
          </div>
        </div>
      </div>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { closeModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/auth/constants'

import translations from './ExplanationModal.translations'

const props = withDefaults(
  defineProps<{
    passwordResetReason?: string
  }>(),
  {
    passwordResetReason: '',
  },
)

const i18n = useI18n()

const paragraphTranslated = computed(() => {
  if (props.passwordResetReason.toUpperCase() === 'PETANQUE_JP_IMPORT') {
    return [
      i18n(translations.paragraphPetanqueJpImport1),
      i18n(translations.paragraphPetanqueJpImport2),
      i18n(translations.paragraphPetanqueJpImport3),
      i18n(translations.paragraphPetanqueJpImport4),
      i18n(translations.paragraphPetanqueJpImport5),
    ]
  }

  return [i18n(translations.paragraphDefault)]
})

function handleClickResetPassword() {
  closeModal(MODAL_NAMES.RESET_PASSWORD_EXPLANATION)
}
</script>
