import { HttpApiError, type HttpEndpoint } from '@backmarket/http-api'
import { postPasswordResetStartFlow } from '@backmarket/http-api/src/api-specs-identity/user/password-reset'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

/*
 * Send form for lost password
 */
export function useLostPassword() {
  async function lostPassword(email: string) {
    const body = { email }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const endpoint: HttpEndpoint<void, any> = postPasswordResetStartFlow

    try {
      await $httpFetch(endpoint, {
        body,
      })

      return {
        error: null,
      }
    } catch (error) {
      return {
        error: error as HttpApiError,
      }
    }
  }

  return { lostPassword }
}
