export default {
  submit: {
    id: 'forgot_password_form_submit',
    defaultMessage: 'Submit',
  },
  email: {
    id: 'forgot_password_form_email',
    defaultMessage: 'Email',
  },
  requiredEmail: {
    id: 'form_rules_required',
    defaultMessage: 'This field is required',
  },
  error500: {
    id: 'forgot_password_error_500',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  error404: {
    id: 'forgot_password_error_404',
    defaultMessage: 'There is no account linked to this email',
  },
  genericError: {
    id: 'forgot_password_generic_error',
    defaultMessage: 'Something went wrong. Please try again.',
  },
}
